<template>
  <a-spin :spinning="loading.list">
    <div class="top-box">
      <div class="new-box">
        <a-button icon="user-add" type="primary" ghost @click="handleAddView">新建管理员</a-button>
      </div>
      <div class="search-box">
        <a-input-search v-model="params.name" placeholder="请输入姓名" style="width: 200px" @search="fetchData" />
      </div>
    </div>
    <div class="manager-user">
      <div class="left-box">
        <a-card title="角色列表">
          <div :class="{'role-item': true , 'role-item-active': params.roleId === ''}" @click="handleSelectRole({id: ''})">
            全部角色
          </div>
          <div v-for="(item, index) in roleList" :key="index" :class="{'role-item':true, 'role-item-active': item.id === params.roleId}" @click="handleSelectRole(item)">
            {{ item.name }}
          </div>
        </a-card>
      </div>
      <div class="right-box">
        <a-table :scroll="{ y: 680 }" :row-key="(record,index)=>{return index}" :data-source="adminList" :pagination="false">
          <a-table-column key="index" title="序号" width="80px">
            <template slot-scope="text,record,index">
              {{ index + 1 }}
            </template>
          </a-table-column>
          <a-table-column key="name" title="名称" width="100">
            <template slot-scope="text, record">
              {{ record.name }}
            </template>
          </a-table-column>
          <a-table-column key="loginName" title="用户名" width="100">
            <template slot-scope="text, record">
              {{ record.loginName }}
            </template>
          </a-table-column>
          <a-table-column key="roleName" title="角色名称" width="100">
            <template slot-scope="text, record">
              {{ record.roleName }}
            </template>
          </a-table-column>
          <a-table-column key="photo" title="头像" width="100">
            <template slot-scope="text, record,index">
              <img style="border-radius:40px" width="40px" height="40px" src="../../../assets/user-icon.jpg" :data="index">
            </template>
          </a-table-column>
          <a-table-column key="val" title="状态" width="100">
            <template slot-scope="text, record">
              <a-tag v-if="record.val === 1" color="blue">
                正常
              </a-tag>
              <a-tag v-if="record.val === 0" color="red">
                停用
              </a-tag>
            </template>
          </a-table-column>
          <a-table-column key="setting" title="操作" width="200px">
            <template slot-scope="text, record">
              <a-button type="primary" ghost @click="handleEditView(record)">
                编辑
              </a-button>
              <a-button v-if="record.val === 1" style="margin-left: 10px" type="danger" ghost @click="handleStopView(record)">
                停用
              </a-button>
              <a-button v-if="record.val === 0" style="margin-left: 10px" type="primary" ghost @click="handleStartView(record)">
                启用
              </a-button>
            </template>
          </a-table-column>
        </a-table>
        <a-pagination
          v-model="params.current"
          style="margin-top: 10px"
          show-size-changer
          :default-current="1"
          :total="params.total"
          :page-size.sync="params.size"
          @change="fetchData"
          @showSizeChange="onShowSizeChange"
        />
      </div>
      <edit-user-modal ref="editModal" :role-list="roleList" @on-ok="fetchData" />
      <add-user-modal ref="addModal" :role-list="roleList" @on-ok="fetchData" />
    </div>
  </a-spin>
</template>

<script>
import adminApi from '../../../api/admin'
import AddUserModal from './component/AddUserModal.vue'
import EditUserModal from './component/EditUserModal.vue'
export default {
  components: { EditUserModal, AddUserModal },
  data() {
    return {
      roleList: [],
      adminList: [],
      loading: {
        list: false
      },
      params: {
        roleId: '',
        size: 10,
        current: 1,
        total: 1,
        name: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    onShowSizeChange(current, size) {
      this.params.size = size
      this.fetchData()
    },
    handleSelectRole(role) {
      this.params.roleId = role.id
      this.fetchData()
    },
    handleAddView() {
      this.$refs.addModal.showAddModal()
    },
    handleEditView(record) {
      this.$refs.editModal.showEditModal(record)
    },
    handleStartView(record) {
      const that = this
      this.$confirm({
        title: `确定启用[${record.name}]用户?`,
        okText: '确定',
        okType: 'primary',
        cancelText: '取消',
        onOk() {
          adminApi.up(record.id).then(res => {
            that.$message.success(res.msg)
            that.fetchData()
          })
        },
        onCancel() {
        }
      })
    },
    handleStopView(record) {
      const that = this
      this.$confirm({
        title: `确定停用[${record.name}]用户?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          adminApi.down(record.id).then(res => {
            that.$message.success(res.msg)
            that.fetchData()
          })
        },
        onCancel() {
        }
      })
    },
    fetchData() {
      this.loading.list = true
      const roleList = adminApi.roleList()
      roleList.then(res => {
        this.roleList = res.data
      })
      const list = adminApi.list(this.params)
      list.then(res => {
        this.adminList = res.data.records
        this.params.total = res.data.total
      })
      Promise.all([roleList, list]).then(_ => {
        this.loading.list = false
      })
    }
  }
}
</script>
<style scoped>
.top-box {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}
.manager-user {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  overflow-y: auto;
}
.left-box {
  width: 300px;
}
.right-box {
  width: 100%;
  margin-left: 10px;
}
.role-item {
  height: 30px;
  line-height: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
.role-item-active {
  color: #1890ff;
}
::v-deep .ant-table-thead > tr > th {
  background-color: #e8f4ff !important;
  color: #1890ff;
}
::v-deep .ant-table-tbody > tr > td {
  background-color: #ffffff !important;
}
::v-deep .ant-table-body::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
::v-deep .ant-table-body::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow   : inset 0 0 2px rgba(0, 0, 0, 0.2);
  background   : #40a9ff;
}
::v-deep .ant-table-body::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background   : #ededed;
}
</style>
